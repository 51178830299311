import '../styles/features.scss';
import React from "react";

function Features() {
  return (
    <div className="features">
      <h2>Lorem ipsum dorem</h2>
      <div className="features__container">
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/omnichannel.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              Omnichannel
            </div>
            <div className="feature__description">
              Budoucnost online obchodování je v omnichannelu
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/expanze.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              Expanze do zahraničí
            </div>
            <div className="feature__description">
              Věříme v expanzi na nové trhy v jiných zemích
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/saas.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              SaaS Application Development
            </div>
            <div className="feature__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/it.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              IT vývoj
            </div>
            <div className="feature__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/cross-border.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              Cross-border
            </div>
            <div className="feature__description">
              Překračujeme hranice online obchodování
            </div>
          </div>
        </div>
        <div className="feature">
          <div className="feature__image-container">
            <img className="feature__image" src="/images/lorem.png" alt="" />
          </div>
          <div className="feature__text">
            <div className="feature__heading">
              Lorem ipsum
            </div>
            <div className="feature__description">
              Duis id molestie est. Aenean sit amet malesuada ante, ut cursus ipsum.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;