import '../styles/App.scss';
import React from "react";
import Hero from "./Hero";
import Features from "./Features";
import About from "./About";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Hero />
      <Features />
      <About />
      <Footer />
    </div>
  );
}

export default App;
