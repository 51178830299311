import '../styles/footer.scss';
import React from "react";

function Footer() {
    return(
        <div className="footer">
            <div className="footer__info">
                <div className="footer__logo">
                    <img src="/images/logo-white.png" alt="Retailys Group" width="230"/>
                    <p>Evropský e-commerce cloud<br/>Nová generace e-shopů</p>
                </div>
                <div className="footer__contact">
                    <p>Infolinka
                        <br/>Po - Pá
                        8:00 - 16:00</p>
                    <p className="footer__ico">
                        <span className="icon icon--phone"/>
                        <a href="tel:+420376383611">+420 376 383 611</a>
                        <br/><a href="tel:+420720579325">+420 720 579 325</a>
                    </p>
                    <p className="footer__ico">
                        <span className="icon icon--mail"/>
                        <a href="mailto:info@retailys.com">info@retailys.com</a>
                    </p>
                </div>
            </div>
            <div className="footer__places">
                <div className="footer__place">
                    <p className="footer__ico">
                        <span className="icon icon--pin"/>
                        <strong>Česká republika</strong>
                        <br/>Retailys.com s.r.o.</p>
                    <p>Musílkova 9/1308
                        <br/>150 00 Praha 5</p>
                    <p>Husovo náměstí 50
                        <br/>339 01 Klatovy</p>
                    <p>Cyrilská 7
                        <br/>602 00 Brno</p>
                    <p className="footer__ico footer__phone">
                        <span className="icon icon--phone"/>
                        <a href="tel:+420720579325">+420 720 579 325</a>
                    </p>
                </div>
                <div className="footer__place">
                    <p className="footer__ico">
                        <span className="icon icon--pin"/>
                        <strong>United Kingdom</strong>
                        <br/>Retailys Ltd.</p>
                    <p>Kemp House 152 - 160
                        <br/>City Road London
                        <br/>EC1V 2NX</p>
                    <p className="footer__ico footer__phone">
                        <span className="icon icon--phone"/>
                        <a href="tel:+420720579325">+420 720 579 325</a>
                    </p>
                </div>
                <div className="footer__place">
                    <p className="footer__ico">
                        <span className="icon icon--pin"/>
                        <strong>Singapore</strong>
                        <br/>Retailys.com Pte. Ltd.</p>
                    <p>52 Hume Avenue #07-07
                        <br/>Singapore 596230</p>
                    <p className="footer__ico footer__phone">
                        <span className="icon icon--phone"/>
                        <a href="tel:+6582650819">+65 8265 0819</a>
                    </p>
                </div>

            </div>
            <div className="social">
                <ul className="reset">
                    <li>
                        <a href="https://www.facebook.com/retailyscom/" target="_blank">
                            <span className="icon icon--fb"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/retailys_com/" target="_blank">
                            <span className="icon icon--instagram"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/retailys" target="_blank">
                            <span className="icon icon--twitter"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/retailys/" target="_blank">
                            <span className="icon icon--linkedin"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCqrUF6r2dfUMUuP791XnuAw" target="_blank">
                            <span className="icon icon--youtube"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;