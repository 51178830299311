import '../styles/hero.scss';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

function Hero() {

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return(
    <div className="hero">
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img className="hero__image" src="/images/hero-1.jpg" alt="" />
          </div>
          <div>
            <img className="hero__image" src="/images/hero-2.jpg" alt="" />
          </div>
          <div>
            <img className="hero__image" src="/images/hero-3.jpg" alt="" />
          </div>
          <div>
            <img className="hero__image" src="/images/hero-4.jpg" alt="" />
          </div>
        </Slider>
      </div>
      <div className="hero-overlay">
        <img className="logo" src="/images/logo.png" alt="Retailys Group" />
        <h1>Lorem ipsum dolor sit amet</h1>
        <h2>Donec adio tempus</h2>
        <Button variant="success" href="https://www.retailys.cz/plugin/Registration/" className="btn">
          Vyzkoušet Retailys
        </Button>
      </div>
    </div>
  );
}

export default Hero;