import '../styles/about.scss';
import React from "react";

function About() {
  return(
    <div className="about">
      <div className="image-container">
        <img className="about__image" src="/images/about.jpg" alt="" />
      </div>
      <div className="about__text">
        <h2>O RETAILYS GROUP a.s.</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer faucibus sodales hendrerit. Duis id molestie est. Aenean sit amet malesuada ante, ut cursus ipsum. Donec finibus purus ut erat iaculis semper. Vestibulum porttitor, erat id rhoncus cursus, massa metus rutrum leo, tincidunt vulputate lectus tortor vel nibh. Vestibulum sed leo ac nibh accumsan posuere sed sit amet nisl. Quisque vitae ligula at mauris faucibus fringilla.
        </p>
      </div>
      <div className="stats-container">
        <div className="stat">
          <div className="stat__top">
            30
          </div>
          <div className="stat__bottom">
            zemí
          </div>
        </div>
        <div className="stat">
          <div className="stat__top">
            100
          </div>
          <div className="stat__bottom">
            klientů
          </div>
        </div>
        <div className="stat">
          <div className="stat__top">
            12 000
          </div>
          <div className="stat__bottom">
            produktů
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;